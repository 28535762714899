<template>
  <div class="shop-personal-order animate__animated animate__fadeIn container">
    <div class="zh_title">订单列表</div>
    <div class="tabList mt_16">
      <zh-table :data="tableData" @row-click="clickDetail">
        <el-table-column
            type="index"
            label="序号"
            align="center"
            width="150"
            fixed
        />
        <template v-for="item in orderColumns" :key="item.fieldKey">
          <el-table-column
              v-if="item.fieldKey === 'orderStatus'"
              align="center"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              <div class="shop-status status1" :class="`status${row.orderStatus}`">{{ row.orderStatusDesc }}</div>
            </template>
          </el-table-column>
          <el-table-column
              v-else
              :align="item.textAlign"
              :label="item.title"
              :width="item.width"
              :sortable="item.sortable"
              :class-name="item.className || 'table_text'"
              show-overflow-tooltip
          >
            <template #default="{row}">
              {{ row[item.fieldKey] }}
            </template>
          </el-table-column>
        </template>
      </zh-table>
    </div>
    <div class="mainPage">
      <el-pagination
          v-model:current-page="pageInfo.pageNumber"
          layout="total, prev, pager, next , jumper"
          :total="pageInfo.total"
          v-model:page-size="pageInfo.pageSize"
          @current-change="handleCurrentChange"
      />
    </div>
    <order-info v-model="showDetail" @closeOrder="getList" :order-sn="chooseOrderSn"/>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import ZhTable from "@components/element/table.vue";
import {orderColumns} from './orderConfig';
import {usePagination} from "@utils/tool/page";
import {shopOrderList} from "@utils/api/shop/order"
import OrderInfo from './orderInfo/oderInfo.vue'

const showDetail = ref(false)
const {pageInfo, reset} = usePagination()
const tableData = ref([])
const {proxy} = getCurrentInstance();
const handleCurrentChange = (val) => {
  pageInfo.pageNumber = val;
  getList();
}
const getList = () => {
  shopOrderList().then(res => {
    if (res.code !== 0) proxy.$message.error(res.msg)
    let list = res.data.list || []
    list.forEach(item => {
      if (item.createTime) {
        item.createTime = proxy.$filters.timeFormat(item.createTime)
      }
      if (item.orderAmount) {
        item.orderAmount = proxy.$filters.toYuan(item.orderAmount, '商城', false)
      }
    })
    tableData.value = res.data.list || []
    pageInfo.total = res.data.total || 0
  })
}
const clickDetail = (e) => {
  chooseOrderSn.value = e.orderSn
  showDetail.value = true
}
const chooseOrderSn = ref('')
onMounted(() => {
  getList()
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "personalInfoOrder"
});
</script>

<style lang="scss" scoped>
.zh_title {
  font-size: 18px !important;
}

.shop-personal-order {
  box-sizing: border-box;
  padding-top: 48px;
  position: relative;
  height: calc(100vh - 146px);
}

.tabList {
  height: calc(100% - 120px);
}
</style>
a