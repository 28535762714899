import request from '@utils/http'

export function shopOrderList(data) {
    return request({
        url: "/carton/shop/order/list",
        method: 'post',
        data
    })
}

//商场订单详情
export function shopOrderDetail(data) {
    return request({
        url: "/carton/shop/order/detail",
        method: 'post',
        data
    })
}

//确认收货
export function shopOrderConfirm(data) {
    return request({
        url: "/carton/shop/order/take",
        method: 'post',
        data
    })
}