<template>
  <el-dialog
      :model-value="modelValue"
      title="订单信息"
      width="1200"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeDiaOrder"
      @open="getDetail"
  >
    <div class="dialog-content">
      <div class="content dp_f" v-loading="loading">
        <base-info :order-info="orderInfo"/>
        <goods-list class="list" :total="total" :info="info" :list="list"/>
      </div>
      <div class="btn-box">
        <button class="mr_16 btn1 w120 wy-button info" @click="handleClick('申请开票')">申请开票</button>
        <button class="mr_16 btn1 w120 wy-button normal" @click="handleClick('申请退款')">申请退款</button>
        <button class="btn1 w120 wy-button primary" v-if="isShowTake" @click="handleTake">确认收货</button>
      </div>
    </div>
    <concat-d-ia :value="showInfo.value" v-model="showInfo.show"/>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, toRefs} from 'vue'
import BaseInfo from "@views/shop/personal/personal/components/orderInfo/component/baseInfo.vue";
import GoodsList from "@views/shop/order/components/confirmDialog/components/list.vue";
import {ElMessageBox} from 'element-plus';
import {shopOrderConfirm, shopOrderDetail} from "@utils/api/shop/order"
import {orderStatus} from '../orderConfig'
import ConcatDIa from "@views/shop/components/concatDIa/index.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false
  },
  orderSn: {
    type: String,
    default: ''
  }
})
const loading = ref(false)
//是否展示收货按钮
const isShowTake = computed(() => orderInfo.value.orderStatus === orderStatus.isTake)
const {modelValue, orderSn} = toRefs(props)
const {proxy} = getCurrentInstance()
const emit = defineEmits(['update:modelValue', 'closeOrder'])
const total = reactive({
  total: "0",
  diff: "0"
})
const orderInfo = ref({})
const handleClick = (text) => {
  showInfo.value = `${text},请联系客服处理`
  showInfo.show = true
}
const showInfo = reactive({
  show: false,
  value: ''
})
const getDetail = () => {
  loading.value = true
  shopOrderDetail({orderSn: orderSn.value}).then(res => {
    loading.value = false
    if (res.code !== 0) return
    orderInfo.value = res.data
    total.total = proxy.$filters.toYuan(res.data.orderAmount, '元', true)
    total.discountAmount = res.data.discountAmount
    list.value = res.data.details || []
    info.value = res.data.map || {}
  })
}
const list = ref([])
const info = ref({})
const closeDiaOrder = () => {
  emit('update:modelValue', false)
  emit('closeOrder')
}

//确认收货
const handleTake = () => {
  ElMessageBox.confirm('确认收货吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    customClass: "zh-message-box shop",
  }).then(() => {
    shopOrderConfirm({orderSn: orderSn.value}).then(res => {
      if (res.code !== 0) return proxy.$message.error(res.msg)
      proxy.$message.success('确认收货成功')
      getDetail()
    })
  }).catch(() => {
  });
}

</script>

<style lang="scss" scoped>
.content{
  align-items: flex-start !important;
}
.btn-box{
  text-align: right;
  margin-top: 72px;
}
.list{
  flex:auto;
  margin-left: 24px;
}

.dialog-content {
  padding: 8px 24px;
}
</style>