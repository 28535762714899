import {reactive} from 'vue'

export function usePagination(defaultPageSize) {
    const pageInfo = reactive({
        pageSize: defaultPageSize ?? 20,
        pageNumber: 1,
        total: 0,
    })

    // 根据实际需求增加更多功能，比如自动管理接口数据等
    function reset() {
        pageInfo.pageNumber = 1
        pageInfo.total = 0
    }

    return {pageInfo, reset}
}


