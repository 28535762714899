<template>
    <div class="base-info">
      <div class="item">
          <div class="key">订单编号</div>
        <div class="value">{{ orderInfo.orderSn }}</div>
      </div>
      <div class="item">
        <div class="key">创建时间</div>
        <div class="value">{{ $filters.timeFormat(orderInfo.createTime) }}</div>
      </div>
      <div class="item">
        <div class="key">企业名称</div>
        <div class="value">{{ orderInfo.companyName }}</div>
      </div>
      <div class="item" v-if="orderInfo.contactUser">
        <div class="key">联系人</div>
        <div class="value">{{ orderInfo.contactUser }}</div>
      </div>
      <div class="item" v-if="orderInfo.contactMobile">
        <div class="key">联系电话</div>
        <div class="value">{{ orderInfo.contactMobile }}</div>
      </div>
      <div class="item" v-if="orderInfo.contactAddress">
        <div class="key">收货地址</div>
        <div class="value">{{ orderInfo.contactAddress }}</div>
      </div>
      <div class="item" v-if="orderInfo.takeNumber">
        <div class="key">快递单号</div>
        <div class="value" @click="copyTakeNumber">{{ orderInfo.takeNumber }}</div>
      </div>
      <div class="item">
        <div class="key">订单状态</div>
        <div class="value">
          <div class="shop-status status1" :class="`status${orderInfo.orderStatus}`">
            {{ orderInfo.orderStatusDesc }}
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import {toRefs} from 'vue';
import {copyText} from "@utils/tool.js";

const props = defineProps({
  orderInfo: {
    type: Object,
    default: () => {
    }
  }
})
const {orderInfo} = toRefs(props)
const copyTakeNumber = () => {
  copyText(orderInfo.value.takeNumber)
  proxy.$message.success('复制成功')
}
</script>

<style lang="scss" scoped>
.base-info{
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  width: 480px;
  box-sizing: border-box;
  .item{
    display: flex;
    width: 100%;
    font-size: 16px;
    margin-bottom: 30px;
    align-items: center;
    &:last-child{
      margin-bottom: 0px;
    }
    .key{
      width: 100px;
      color: $shopFsColor2;
    }
    .value{
      @include textOverflow(300px);
      color: $shopFsColor;
    }
  }
}
</style>