export const orderColumns = [
    {
        title: "订单编号",
        fieldKey: "orderSn",
        className: 'table_text',
        textAlign: 'left',
        width: 300,
    },
    {
        title: "创建人",
        fieldKey: "createUserName",
        className: 'table_text2',
        textAlign: 'left',
        width: 200
    },
    {
        title: "创建时间",
        fieldKey: "createTime",
        className: 'table_text',
        textAlign: 'left',
    },
    {
        title: "订单金额（元）",
        fieldKey: "orderAmount",
        className: 'table_text2',
        textAlign: 'left',
        width: 150
    },
    {
        title: "收货地址",
        fieldKey: "contactAddress",
        className: 'table_text2',
        textAlign: 'left',
    },
    {
        title: "订单状态",
        fieldKey: "orderStatus",
        className: 'table_text',
    },
]
export const orderStatus = {
    waitPay: 0,
    waitTake: 1,
    isTake: 2,
    takeSuccess: 3,
    invoiceSuccess: 4,
    backIn: 11,
    backSuccess: 12,
}